// AboutUs.jsx

import React from 'react';
import './AboutUs.css'; // Import the CSS file

const AboutUs = () => {
  return (
    <div className="about-section">
      <img
        id="about-image"
        src="/Images/Home-img/hero-img.png" 
        alt="About Us"
      />
      <div className="about-content">
        <h2>
        Grow your business with Equiverge Technologies</h2>
       <h4>We are team of talented designers making classic websites
       </h4>
      </div>
    </div>
  );
};

export default AboutUs;
