import React, { useState } from 'react';
import './Grow.css';

const BackgroundComponent = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="background-container">
      <div className="image-containerbg">
        {/* Left side content (image) */}
        <img src="/Images/Home-img/hero-img.png" alt="Background" />
      </div>
      
      <div className="content-containerbg">
        {/* Right side content */}
        <h1>Powereing Digitial Industries</h1>
        <p> In today's competitive job market, specialized skill development courses have gained prominence..</p>
        <button className="custom-button">
          Join for Free 
        </button>
      </div>
    
    </div>
  );
};

export default BackgroundComponent;

