import React, { useState } from 'react';
import './skills.css';
import { LiaCheckSolid } from 'react-icons/lia';
import { AiFillPlayCircle } from 'react-icons/ai';
import {Link} from 'react-router-dom'

const Skills = () => {
  const [showVideo, setShowVideo] = useState(false);
  // const videoId = 'CPFoe5q5jT8';

  const handleIconClick = () => {
    setShowVideo(true);
  };

  return (
    <div className="container2">
      <div className="content">
        {/* <img src="https://preview.colorlib.com/theme/courses/assets/img/icon/about.svg" alt="hii" /> */}
        <h2 className="custom-h2">Learn new skills online with top educators</h2>
        <div className="icon-list">
          <div className="icon-item">
            <LiaCheckSolid style={{ color: 'orange', fontSize: '26px', paddingtop: '40px' }} />
            <p> Techniques to engage effectively with vulnerable children and young people.</p>
          </div>

          <div className="icon-item">
            <LiaCheckSolid style={{ color: 'orange', fontSize: '26px', margintop: '20px' }} />
            <p> Techniques to engage effectively with vulnerable and young people.Discover tools and techniques to.</p>
          </div>

          <div className="icon-item">
            <LiaCheckSolid style={{ color: 'orange', fontSize: '26px', margintop: '20px' }} />
            <p> Techniques to engage effectively with vulnerable children and young people.Techniques to engage effectively . .</p>
          </div>
        </div>
        <button className="custom-button">Know More</button>
      </div>
      <div className="music-icon"style={{marginRight:'0px'}}>
        {!showVideo ? (
          <Link to="#" onClick={handleIconClick}>
            <AiFillPlayCircle style={{ color: 'orange', fontSize: '100px', marginRight: '250px' }} />
          </Link>
        ) : (
          <iframe
            width="490"
            height="390"
            src={`/Images/Home-img/Video.mp4`}
            frameBorder="0"
            allowFullScreen
            title="Embedded YouTube Video"
          className='video-frame'
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Skills;