import React from 'react';
import './CoursesScrum.css';

const ScrumMasterCourse = () => {
  return (
    <div className="container-Ui">
      <div className="left">
        <h2><strong>Scrum Master Course Overview</strong></h2>
        <p>
          Dive into the essentials of Scrum methodology with our Scrum Master Course. This course equips you with the skills and knowledge needed to excel in the role of a Scrum Master. Learn the art of servant leadership, where your primary responsibility is to facilitate team collaboration, remove impediments, and ensure the smooth application of Scrum processes. The course covers key topics such as organizing and participating in Scrum ceremonies, shielding the team from external disruption.
        </p>
       
      </div>
      <div className="right">
        <img src="/Images/Courses-img/Scrum.png" alt="Scrum Master Course" />
      </div>
    </div>
  );
};

export default ScrumMasterCourse;
