// SignupPopup.js

import React from 'react';
import './Sign-Up.css';
import { BsFacebook } from 'react-icons/bs';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { SiIndeed } from 'react-icons/si';
// import { BiLogoGmail } from 'react-icons/bi';
import { FaWhatsapp } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';



const SignupPopup = ({ onClose }) => {
  const openSocialMedia = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Join for Free</h2>
       
        <div className="social-icons1">
          
          <button className="social-login-button" onClick={() => openSocialMedia('https://www.linkedin.com/in/equiverge-technologies-941b8729b')}>
            <AiOutlineLinkedin />
            LinkedIn
          </button>

          <button className="social-login-button" onClick={() => openSocialMedia('https://secure.indeed.com/settings')}>
            <SiIndeed />
            Indeed
          </button>

          <button className="social-login-button" onClick={() => openSocialMedia('https://www.facebook.com/people/Equiverge-Technologies/61550080665812/')}>
            <BsFacebook />
            FaceBook
          </button>

          <button className="social-login-button" onClick={() => openSocialMedia('https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D')}>
            <AiOutlineInstagram />
            Instagram
          </button>

          <button className="social-login-button" onClick={() => openSocialMedia('https://api.whatsapp.com/send?phone=%2B918712921597&data_filter_required=ARBN3JJ-Vozen0jnTk13qfhOMpp4KSVOnLRPaAnYRmdXfLmuzRiNjP5xi3VeK-oLJmF4pNFtbzL1iCjTKuj5wZejLIDp-0MhOhYgPUVSsLDA9vXucQN5zsC_1A56NUoEZ594hWz62m1McRgeGVelMp3DSA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR060OmQpYzlWY4php3Zwp9lU_u4uo1Q2A1CesywI1Y3fRbDMF739jd3c4Y')}>
            <FaWhatsapp />
            WhatsApp
          </button>

          <button className="specific-close-button" onClick={onClose}>
          Close
        </button>
        </div>
      </div>
    </div>
  );
};

export default SignupPopup;
