import React from 'react';
import './ProjectDetails.css'; // Import your CSS file

const ProjectDetails = () => {
  return (
    <div className="project-container">
      <div className="image-container">
        <img src="/Images/About-img/Project-img.jpeg" alt="" />
      </div>
      <div className="content-container">
      <h2>About Project_Details </h2>

        <p>
          At <span>Equiverge_Technologies </span>, we believe in learning by doing. That's
          why our training programs provide hands-on experience with real
          projects, giving you the practical skills needed in the industry.
        </p>

        <p>
          Recent projects undertaken by our students include enhancing the
          user interface of live e-commerce websites, analyzing real business
          data for insights, and collaborating on mobile app development for
          community impact. Gain valuable experience and problem-solving
          abilities that set you apart in the competitive job market.
        </p>
      </div>
    </div>
  );
};

export default ProjectDetails;
