// Home1.js

import React, { useState } from 'react';
import './HomeBody.css';
import SignupPopup from './SignUpPopUp';


const Home1 = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div>
      <div className="container4">
        <div className="content">
          <h2 className="custom-h2">Online learning platform</h2>
          <h3 className="custom-p">
            Equiverge Technologies Company provides quality coaching by experienced faculty 100% Real Time Work and Projects Explanations
          </h3>
          <button className="custom-button" onClick={openPopup}>
            Join for Free
          </button>
        </div>
      </div>
    
      {isPopupOpen && <SignupPopup onClose={closePopup} />}
    </div>
  );
};

export default Home1;
