import React from 'react';
import './CoursesScrum.css';

const UiCourse = () => {
  return (
    <div className="container-Ui">
      <div className="left">
        <h2><strong>UI Design: </strong>FrontEnd Development</h2>
        <ul>
          <p><strong>Visual Hierarchy:</strong> Organizing elements to guide users' attention, emphasizing essential components and actions.
          Consistency Maintaining uniformity in design elements like colors, typography, and layout across the application.
           Simplifying complex concepts and processes for easy comprehension and navigation.
       Providing immediate and clear feedback for user actions to enhance the sense of control and understanding.
           Ensuring that the interface is usable by people of all abilities and disabilities.</p>
        </ul>

       
        
      </div>
      <div className="right">
        {/* Your image can go here */}
        <img src="/Images/Courses-img/FrontEnd.png" alt="Your Image" />
      </div>
    </div>
  );
};

export default UiCourse;
