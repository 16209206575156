import React from 'react';
import './Automation.css';

const TestingCourse = () => {
  return (
    <div className="container-Testing">
      <div className="left">
        <img src="/Images/Courses-img/Automation-Testing.png" alt="Your Image" />
      </div>
      <div className="right">
        <h2><strong >Testing:</strong> Automation Testing</h2>
        <p><strong >Software testing</strong> is a systematic process of evaluating software applications to identify and fix potential issues before they reach end-users. It involves various techniques, methodologies, and tools to validate the software's functionality, performance, security, and overall user experience. Effective testing is essential for delivering high-quality, reliable, and error-free software solutions.</p>

       
      </div>
    </div>
  );
};

export default TestingCourse;
