import React from 'react';
import './Footer.css'; 
import { FaInstagram, FaTwitter, FaFacebook, FaWhatsapp, FaMessenger } from 'react-icons/fa';
import { Link } from 'react-router-dom';
function handleSocialLinkClick(platform) {
 
  console.log(`Clicked on ${platform}`);
}

function Footer() {
  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="contentBox">
          <div className='logo'>
            <div>
              <img src="/Images/Main-img/logo-removebg-preview.png" alt="" width="200" height="" />
            </div>
          </div>
          <div className='social-links1'>
            <a href="https://www.instagram.com/your_instagram_username" target="_blank" rel="noopener noreferrer"><FaInstagram className='social-icons2' /></a>
            <a href="https://www.facebook.com/your_facebook_page" target="_blank" rel="noopener noreferrer"><FaTwitter className='social-icons2' /></a>
            <a href="https://wa.me/your_whatsapp_number" target="_blank" rel="noopener noreferrer"><FaFacebook className='social-icons2' /></a>
            <a href="https://www.indeed.com" target="_blank" rel="noopener noreferrer"><FaWhatsapp className='social-icons2' /></a>
          </div>
        </div>
        <div className="contentBox">
          <h3>Customer Care</h3>
          <ul className="social">
          <li><Link to="/">--- HOME</Link></li>
<li><Link to="/about">--- ABOUT</Link></li>
<li><Link to="/course">--- COURSES</Link></li>
<li><Link to="/blog">--- BLOG</Link></li>
<li><Link to="/contact">--- CONTACT</Link></li>
          </ul>
        </div>
        <div className="contentBox">
          <h3>Favourites</h3>
          <ul className="social">
            <li><a href="#">Design & creative</a></li>
            <li><a href="#">communication Skills</a></li>
            <li><a href="#">Real-Time Works</a></li>
            <li><a href="#">Programing</a></li>
            <li><a href="#">Architecture</a></li>
          </ul>
        </div>
        <div className="contentBox">
          <h3>Follow Us</h3>
          <ul className="social">
            <li>
              <a href="https://www.instagram.com/equiverge_technologies_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank" rel="noopener noreferrer" onClick={() => handleSocialLinkClick('Instagram')}>
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Equiverge-Technologies/61550080665812/" target="_blank" rel="noopener noreferrer" onClick={() => handleSocialLinkClick('Facebook')}>
                Facebook
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=%2B918712921597&data_filter_required=ARBN3JJ-Vozen0jnTk13qfhOMpp4KSVOnLRPaAnYRmdXfLmuzRiNjP5xi3VeK-oLJmF4pNFtbzL1iCjTKuj5wZejLIDp-0MhOhYgPUVSsLDA9vXucQN5zsC_1A56NUoEZ594hWz62m1McRgeGVelMp3DSA&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR060OmQpYzlWY4php3Zwp9lU_u4uo1Q2A1CesywI1Y3fRbDMF739jd3c4Y" target="_blank" rel="noopener noreferrer" onClick={() => handleSocialLinkClick('WhatsApp')}>
                WhatsApp
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/equiverge-technologies-941b8729b' target="_blank" rel="noopener noreferrer" onClick={() => handleSocialLinkClick('Indeed')}>
              LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyrights">
        <hr />
        <p> © Copyright Equiverge Technologies @ 2023 All Rights Reserved ..</p>
      </div>
    </footer>
  );
}

export default Footer;
