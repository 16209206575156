import React, { useState } from 'react';
import './Intern.css';


const Course1 = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className="container-course">
      <div className="left">
        <h2>Internship</h2>
        <h1>We are a dynamic team of creative people.</h1>
        <h5>What we are</h5>
        <p>The automated process all your website tasks. Discover tools and techniques to engage effectively with vulnerable children and young people.</p>
        <button className='slide'>
          Join now for free
        </button>
      </div>
      <div className="right">
        <img src="/Images/Home-img/Intern.png" alt="Your Image" />
      </div>
     
    </div>
  );
};

export default Course1;