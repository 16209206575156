// Import React and other necessary modu
import React, { useState ,useEffect,useRef } from 'react';
import { Link, useNavigate ,NavLink,useLocation} from 'react-router-dom';
import { MdOutlineLegendToggle } from "react-icons/md";

import { BsFacebook } from 'react-icons/bs';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { SiIndeed } from 'react-icons/si';
import { BiLogoGmail } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiEnvelope } from 'react-icons/bi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { CiCircleList } from "react-icons/ci";

import { IoCallOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { IoPersonOutline } from 'react-icons/io5';
import "./NavBar.css";
import "./Form.css";
import "./Join.Css";

// NavBar component
function Header() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const handleDropdown = () => setDropdown(!dropdown);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);

  const [isJoinPopUpOpen, setIsJoinPopUpOpen] = useState(false);
  const [isEmailPopUpOpen, setIsEmailPopUpOpen] = useState(false);

  const [name, setEmailName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const [isSignUpPopUpOpen, setIsSignUpPopUpOpen] = useState(false);

  const [createPassword, setCreatePassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  const [isIndeedPopUpOpen, setIsIndeedPopUpOpen] = useState(false);
  const [indeedPopUpOpen, setIndeedPopUpOpen] = useState(false);

  const [indeedName, setIndeedName] = useState('');
  const [indeedEmail, setIndeedEmail] = useState('');
  

  const [indeedContactNo, setIndeedContactNo] = useState('');
  const [indeedCreatePassword, setIndeedCreatePassword] = useState('');
  const [indeedConfirmPassword, setIndeedConfirmPassword] = useState('');

  const [facebookPopUpOpen, setFacebookPopUpOpen] = useState(false);
  const [facebookName, setFacebookName] = useState('');
  const [facebookEmail, setFacebookEmail] = useState('');
  const [facebookContactNo, setFacebookContactNo] = useState('');
  const [facebookCreatePassword, setFacebookCreatePassword] = useState('');
  const [facebookConfirmPassword, setFacebookConfirmPassword] = useState('');

  const [linkedInRegistrationSuccess, setLinkedInRegistrationSuccess] = useState(false);
  const [emailRegistrationSuccess, setEmailRegistrationSuccess] = useState(false);
  const [facebookRegistrationSuccess, setFacebookRegistrationSuccess] = useState(false);
  const [indeedRegistrationSuccess, setIndeedRegistrationSuccess] = useState(false);
  
  
  const [isLinkedInPopUpOpen, setIsLinkedInPopUpOpen] = useState(false);
  const [linkedInPopUpOpen, setLinkedInPopUpOpen] = useState(false);
  const [linkedInName, setLinkedInName] = useState('');
  const [linkedInEmail, setLinkedInEmail] = useState(''); 
  const [linkedInContactNo, setLinkedInContactNo] = useState('');
  const [linkedInCreatePassword, setLinkedInCreatePassword] = useState('');
  const [linkedInConfirmPassword, setLinkedInConfirmPassword] = useState('');


  const [isFacebookPopUpOpen, setIsFacebookPopUpOpen] = useState(false);
   const [facebookUsername, setFacebookUsername] = useState(''); // Add username state
  const [facebookPassword, setFacebookPassword] = useState(''); // Add password state
  const [isGooglePopUpOpen, setGooglePopUpOpen] = useState(false);


  const facebookPopUpRef = useRef(null);


  const linkedInPopUpRef = useRef(null);
  const indeedPopUpRef = useRef(null);
  const joinPopUpRef = useRef(null);
  const emailPopUpRef = useRef(null);
  const signUpPopUpRef = useRef(null);

  const popupRef = useRef(null);

  const [showIcon, setShowIcon] = useState(false);

  const closeAllPopups = () => {
    setIndeedRegistrationSuccess(false);
    setLinkedInRegistrationSuccess(false)
    setEmailRegistrationSuccess(false)
    setFacebookRegistrationSuccess(false)
    closeJoinPopUp();
  
  };


  const handleClickOutsideEmailPopUp = (e) => {
    if (emailPopUpRef.current && !emailPopUpRef.current.contains(e.target)) {
      closePopUp();
    }
  };

  const handleClickOutsideSignUpPopUp = (e) => {
    if (signUpPopUpRef.current && !signUpPopUpRef.current.contains(e.target)) {
      closeSignUpPopUp();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideEmailPopUp);
    document.addEventListener('mousedown', handleClickOutsideSignUpPopUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEmailPopUp);
      document.removeEventListener('mousedown', handleClickOutsideSignUpPopUp);
    };
  }, []);
const handleClickOutsideJoinPopUp = (e) => {
  if (joinPopUpRef.current && !joinPopUpRef.current.contains(e.target)) {
    closeJoinPopUp();
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideJoinPopUp);

  return () => {
    document.removeEventListener('mousedown', handleClickOutsideJoinPopUp);
  };
}, []);

const handleClickOutsideIndeed = (e) => {
  if (indeedPopUpRef.current && !indeedPopUpRef.current.contains(e.target)) {
    closeIndeedPopUp();
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideIndeed);

  return () => {
    document.removeEventListener('mousedown', handleClickOutsideIndeed);
  };
}, []);


  const handleClickOutsideLinkedIn = (e) => {
    if (linkedInPopUpRef.current && !linkedInPopUpRef.current.contains(e.target)) {
      closeLinkedInPopUp();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideLinkedIn);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLinkedIn);
    };
  }, []);

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideIndeed);

  return () => {
    document.removeEventListener('mousedown', handleClickOutsideIndeed);
  };
}, []);

  const handleClickOutsideFacebook = (e) => {
    if (facebookPopUpRef.current && !facebookPopUpRef.current.contains(e.target)) {
      closeFacebookPopUp();
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFacebook);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFacebook);
    };
  }, []);
 

  const handleClickOutside = (e) => {
    if (emailPopUpRef.current && !emailPopUpRef.current.contains(e.target)) {
      closeEmailPopUp();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openGooglePopUp = () => {
    setGooglePopUpOpen(true);
  };

 
  const openLinkedInPopUp = () => {
    setIsLinkedInPopUpOpen(true);
  };
  const handleLinkedInJoin = () => {
   
    
    setLinkedInRegistrationSuccess(true);
    closeLinkedInPopUp();
  };

 
  
  


  const handleBackButton = () => {
    
   
    closeEmailPopUp();
    closeFacebookPopUp();
    closeIndeedPopUp();
    closeLinkedInPopUp();

    
  };
  
  const handleEmailJoin = () => {
    
    setEmailRegistrationSuccess(true);

    // Close the email pop-up
    closeEmailPopUp();
  };

  const handleFacebookJoin = () => {
    
    setFacebookRegistrationSuccess(true);

    // Close the Facebook pop-up
    closeFacebookPopUp();
  };


  

  

  const openFacebookPopUp = () => {
    setIsFacebookPopUpOpen(true);
  };

  const closeFacebookPopUp = () => {
    setIsFacebookPopUpOpen(false);
  };

  const [indeedProfileId, setIndeedProfileId] = useState('');
  const [indeedPassword, setIndeedPassword] = useState('');



  const continueWithGoogle = () => {
    
    console.log('Continuing with Google account');
   setIndeedPopUpOpen(false);
  };

  const handleIndeedJoin = () => {

    setIndeedRegistrationSuccess(true);
    closeIndeedPopUp();
  };
  
  const openIndeedPopUp = () => {
    setIsIndeedPopUpOpen(true);
  };

  const closeIndeedPopUp = () => {
    setIsIndeedPopUpOpen(false);
  };

 

  

 

  const closeLinkedInPopUp = () => {
    setIsLinkedInPopUpOpen(false);
  };



  const openEmailPopUp = () => {
    setIsEmailPopUpOpen(true);
  };

  

  
  const openSignUpPopUp = () => {
    setIsSignUpPopUpOpen(true);
  };

  const closeSignUpPopUp = () => {
    setIsSignUpPopUpOpen(false);
  };

  const handleSignUp = () => {
   
    closeSignUpPopUp();
  };

  const openJoinPopUp = () => {
    setIsJoinPopUpOpen(true);
  };

  const closeJoinPopUp = () => {
    setIsJoinPopUpOpen(false);
    setIsEmailPopUpOpen(false);
  };


  const closeEmailPopUp = () => {
    setIsEmailPopUpOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    mobile: '',
    education: '',
    yearOfPassedOut: '',
    percentage: '',
    message: '',
  });
  

  const handleJoinWithIndeed = () => {
   
    window.location.href = '/auth/indeed'; 
  };

  const openPopUp = () => {
    setIsPopUpOpen(true);
  };

  const closePopUp = () => {
    setIsPopUpOpen(false);
  };
  
  const closePopUp1 = () => {
    setIsJoinPopUpOpen(false); 
  }
 
 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
   
  const closePoUp = () => {
    setIsPopUpOpen(false);
    setIsPasswordRequired(false);
  };

  const closeJoiPopUp = () => {
    setIsJoinPopUpOpen(false);
    setIsPasswordRequired(false);
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('formData', JSON.stringify(formData));
    closePopUp();
    setIsSuccessPopupOpen(true);
  };

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src="/Images/Main-img/logo-removebg-preview.png" alt="Logo" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item" onMouseEnter={handleDropdown} onMouseLeave={handleDropdown}>
              <NavLink
                exact
                to="/course"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Course
              </NavLink>
             
            </li>
            
            <li className="nav-item">
              <NavLink
                exact
                to="/blog"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact 
              </NavLink>
            </li>
            
            {/* Add Join and Login buttons */}
            <button className="custom-button" onClick={() => setIsJoinPopUpOpen(true)}>
              
                Join
              
            </button>

            
        {isJoinPopUpOpen && (
          <div className="popup-background">
           <div className="popup-form1" ref={joinPopUpRef}>
             <h2>Join Us</h2>
              <div className="social-buttons">
              <button className="social-login-button" onClick={openEmailPopUp}>
              <BiLogoGmail />
              E-mail
            </button>
                      
                  {isEmailPopUpOpen && (
              <div className="email-popup" ref={emailPopUpRef}>
                <h2>Join Us</h2>

                <div className="icon-field">
                <IoPersonOutline />
      <input
        type="text"
        placeholder="Enter your name"
        onChange={(e) => setEmailName(e.target.value)}
      />
    </div>
               
                <div className="icon-field">
                  <BiEnvelope />
                  <input
                    type="email"
                    placeholder="Enter your email"
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="icon-field">
                <IoCallOutline />
                  <input
                    type="tel"
                    placeholder="Enter your contact number"
                    onChange={(e) => handleInputChange(e, 'contactNo')}
                  />
                </div>
                <div className="icon-field">
                  <RiLockPasswordLine />
                  <input
                    type="password"
                    placeholder="Create your password"
                    onChange={(e) => setCreatePassword(e.target.value)}
                  />
                </div>
                <div className="icon-field">
                  <RiLockPasswordLine />
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div>
                <button className="join-button" onClick={handleEmailJoin}>
                  Register
                </button>
                <button className="join-button" onClick={handleBackButton}>
            Cancel
          </button>
          </div>
              </div>
            )}
        
        
        {emailRegistrationSuccess && (
            <div className="popup-background">
              <div className="popup-form">
                <div className="popup-content">
                  <h2>Email Registration Successful!</h2>
                  <button onClick={closeAllPopups}className="join-button">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
            
            <button className="social-login-button" onClick={openLinkedInPopUp}>
        <AiOutlineLinkedin />
        LinkedIn
      </button>
      {isLinkedInPopUpOpen && (
          <div className="email-popup" ref={linkedInPopUpRef}>
            <h2>Join Us</h2>
          <div className="icon-field">
            <IoPersonOutline />
            <input
              type="text"
              placeholder="Enter your LinkedIn Profile"
              value={linkedInName}
              onChange={(e) => setLinkedInName(e.target.value)}
            />
          </div>
          <div className="icon-field">
            <RiLockPasswordLine />
            <input
              type="password"
              placeholder="Create your password"
              value={linkedInCreatePassword}
              onChange={(e) => setLinkedInCreatePassword(e.target.value)}
            />
          </div>
          <div className="icon-field">
            <IoCallOutline />
            <input
              type="tel"
              placeholder="Enter your contact number"
              value={linkedInContactNo}
              onChange={(e) => setLinkedInContactNo(e.target.value)}
            />
          </div>
          <div>
          <button className="join-button" onClick={handleLinkedInJoin}>
            Register
          </button>
          <button className="join-button" onClick={handleBackButton}>
            Cancel
          </button>
          </div>
          <button className="join-button1" onClick={continueWithGoogle}>
            Continue with Google
          </button>
        </div>
        
      )}


      {/* Separate pop-up for LinkedIn registration success */}
      {linkedInRegistrationSuccess && (
        <div className="popup-background">
          <div className="popup-form">
            <div className="popup-content">
              <h2>LinkedIn Registration Successful!</h2>
              <button onClick={closeAllPopups} className="join-button">
                Ok
              </button>
            </div>
          </div>
        </div>
      )}

<button className="social-login-button" onClick={openIndeedPopUp}>
        <SiIndeed />
        Indeed
      </button>
{isIndeedPopUpOpen && (
   <div className="email-popup" ref={indeedPopUpRef}>
      <h2>Join Us</h2>
    <div className="icon-field">
      <IoPersonCircleOutline />
      <input
        type="text"
        placeholder="Enter your Indeed Profile ID"
        onChange={(e) => setIndeedProfileId(e.target.value)}
      />
    </div>
    <div className="icon-field">
      <RiLockPasswordLine />
      <input
        type="password"
        placeholder="Enter your Indeed Password"
        onChange={(e) => setIndeedPassword(e.target.value)}
      />
    </div>
    <div className="icon-field">
      <IoCallOutline />
      <input
        type="tel"
        placeholder="Enter your contact number"
        onChange={(e) => setIndeedContactNo(e.target.value)}
      />
    </div>
    {/* Add Google account option */}
    <div>
          <button className="join-button" onClick={handleIndeedJoin}>
            Register
          </button>
          <button className="join-button" onClick={handleBackButton}>
            Cancel
          </button>
          </div>
          <button className="join-button1" onClick={continueWithGoogle}>
            Continue with Google
          </button>
       </div>
       )}
         {indeedRegistrationSuccess && (
            <div className="popup-background">
              <div className="popup-form">
                <div className="popup-content">
                  <h2>Indeed Registration Successful!</h2>
                  <button onClick={closeAllPopups} className="join-button">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}
               
               <button className="social-login-button" onClick={openFacebookPopUp}>
        <BsFacebook />
        Facebook
      </button>
      {isFacebookPopUpOpen && (
        <div className="email-popup"ref={facebookPopUpRef}>
          <h2>Join Us</h2>
          
          <div className="icon-field">
            <IoPersonOutline />
            <input
              type="text"
              placeholder="Enter your username"
              onChange={(e) => setFacebookUsername(e.target.value)}
            />
          </div>
         
        
          <div className="icon-field">
            <RiLockPasswordLine />
            <input
              type="password"
              placeholder="Enter your password"
              onChange={(e) => setFacebookCreatePassword(e.target.value)}
            />
          </div>
          
          <button className="join-button" onClick={handleFacebookJoin}>
            Register
          </button>
          <button className="join-button" onClick={handleBackButton}>
            Cancel
          </button>
          <button className="join-button1" onClick={continueWithGoogle}>
            Continue with Google
          </button>
        </div>
      )}
         {facebookRegistrationSuccess && (
            <div className="popup-background">
              <div className="popup-form">
                <div className="popup-content">
                  <h2>Facebook Registration Successful!</h2>
                  <button onClick={closeAllPopups} className="join-button">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          )}

               <div className='join-buttons'>
            <button className="join-button" onClick={handleEmailJoin}>
              Join
            </button>
         
      
    
      {/* End of Email Pop-up */}
                 
                

                  <button onClick={closePopUp1} className="join-button">
                   Back
                  </button>
                  </div>
              </div>
          
            </div>
          </div>
        )}
            <button className="custom-button" onClick={openPopUp}>
        
                Login
             
            </button>

            {isPopUpOpen && (
          <div className="popup-background">
             <div ref={popupRef} className="popup-form">
              <div className="popup-content1">
                <h2>Login</h2>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                <button onClick={handleEmailJoin} className="join-button">
                  Login
                </button>
                <button className="join-button"onClick={closePopUp}>Close</button>
                </div>
                {/* <button onClick={openSignUpPopUp} className="join-button1">
          Sign Up
        </button> */}
       
               
              </div>
             
{/*             
        {isSignUpPopUpOpen && (
          <div className="popup-background">
           <div className="popup-form" ref={signUpPopUpRef}>
              <div className="popup-content1">
                <h2>Sign Up</h2>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="contactNo">Contact No:</label>
                  <input
                    type="tel"
                    id="contactNo"
                    name="contactNo"
                    value={formData.contactNo}
                    onChange={(e) => handleInputChange(e, 'contactNo')}
                    required
                  />
                </div>
                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                
                <div className='SignUp'>
                <button onClick={handleSignUp} className="join-button">
                  Sign Up
                </button>
                <button onClick={closeSignUpPopUp} className="join-button1">
                Close
              </button>
             </div>
              </div>
              
            </div>
          </div>
        )} */}
      
            </div>
          </div>
        )}
      
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            {/* Your icon or menu toggle button */}
            <CiCircleList className="Nav-icon"/>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;