import React from 'react';
import './TestingAppl.css'; 


const TestingDetails = () => {
  return (
    <div className="Testing-container">
     
      <div className="content-Testing">
        <h2>Testing Application</h2>
        <p>
          Experience real-world testing scenarios at <span>Equiverge_Technologies </span>. Our training programs go beyond theory, providing hands-on exposure to testing applications in real-time environments.
        </p>

        <p>
          Recent projects undertaken by our students involve testing and quality assurance for live applications. From identifying and fixing bugs to collaborating on testing strategies, our students gain practical experience that prepares them for success in the dynamic field of software testing.
        </p>
      </div>

      <div className="image-Testng">
        <img src="/Images/About-img/Testing-img.avif" alt="" />
      </div>
    </div>
  );
};

export default TestingDetails;
