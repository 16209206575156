// TeamMember.js
import React from 'react';
import './Team.css';

const TeamMember = ({ name, designation, image, socialIcons }) => {
  return (
    <div className="team-member">
      <div className="member-image">
        <img src={image} alt={`${name}'s avatar`} />
        <div className="social-icons">
          {socialIcons.map((icon, index) => (
            <a key={index} href={icon.link} target="_blank" rel="noopener noreferrer">
              <img src={icon.icon} alt={icon.alt} />
            </a>
          ))}
        </div>
      </div>
      <p className="member-name">{name}</p>
      <p className="member-designation">{designation}</p>
    </div>
  );
};

export default TeamMember;
