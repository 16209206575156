// App.js

import React from 'react';
import Courses from './Courses';
import ScrumMasterCourse from './ScrumMaster';
import JavaCourse from './JavaCourse';
import UiCourse from './Frontend';
import TestingCourse from './Automation';
import ManualTestingCourse from './Manual';
import BACourse from './BA';

const MainCourse = () => {
  return (
    <div>
      <Courses/>
     <ScrumMasterCourse/>
     <JavaCourse/>
     <UiCourse/>
     <TestingCourse/>
     <ManualTestingCourse/>
     <BACourse/>
    </div>
  );
};

export default MainCourse;
